<template lang="">
  <div>
    <Card>
      <template #header> Yeni Albüm Bilgileri </template>
      <div class="row">
        <div class="col-md-3 col-12">
          <div class="form-group">
            <label for="name">Albüm Adı</label>
            <input
              type="text"
              id="name"
              class="form-control"
              
              v-model="albumname"
            />
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="form-group">
            <label for="date">Albüm Tarihi</label>
            <input type="date" id="date" class="form-control" />
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="form-group">
            <label for="type">Albüm Yeri</label>
            <select
              name=""
              id="type"
              class="form-control"
              v-model="albumlocation"
            >
              <option value="" selected>Seçiniz</option>
              <option value="Tapu">Tapu</option>
              <option value="Ziyaret">Ziyaret</option>
              <option value="Sikayet">Şikayet</option>
              <option value="Bankamdan">Bankadan</option>
            </select>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="form-group">
            <label for="aciklama">Albüm Açıklaması</label>
            <textarea
              name=""
              id="aciklama"
              rows="1"
              class="form-control"
              v-model="albumdescription"
            ></textarea>
          </div>
        </div>
        <div class="col-md-9 col-12">
          <b-form-group class="mb-0">
            <label for="file"> Bilgisayar'dan Dosya seç </label>
            <b-form-file
              id="file"
              class="rounded-sm"
              placeholder="Dosya seçiniz"
              drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
              accept="image/*"
              multiple
              v-model="images"
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </b-form-group>
        </div>
        <div class="col-md-3 col-12 d-flex align-items-end">
          <b-button variant="light" @click="photoPost">
            <i class="flaticon2-search-1"></i>
            Seçilen dosyaları ekle
          </b-button>
        </div>
      </div>
      <b-alert show variant="secondary" class="mt-4"
        >Görsellerde yapılan değişiklikler anlık kayıt edilir. Geri dönüşü
        yapılamaz.</b-alert
      >
      <div ref="imageView"></div>
      <div class="row">
        <div class="col-md-4 col-12" v-for="(photo,key) in photos" style="flex:1">
          <b-card img-top tag="article" class="dveb-gray-card" no-body>
            <img
              :src="`https://bankamdanapi.dvebdemo.com.tr/${photo.photo}`" @click="editImg(photo)" style="height:250px;object-fit:cover"
            >
            <div class="row p-4">
              <div class="form-group w-100">
                <label for="">Fotoğraf Açıklaması</label>
                <input
                  type="text"
                  class="form-control"
                  :value="photo.photo_description"
                  @input="updatePhotoDescription(key, $event.target.value)"
                />
              </div>
              <div class="form-group w-100">
                <label for="">İçerik</label>
                <select class="form-control" :value="photo.photo_type" @input="updatePhotoType(key, $event.target.value)"> 
                  <option value="DİĞER">DİĞER</option>
                  <option value="PROJE">PROJE</option>
                  <option value="RUHSAT">RUHSAT</option>
                  <option value="RESMİ BELGE">RESMİ BELGE</option>
                  <option value="DIŞ MEKAN">DIŞ MEKAN</option>
                  <option value="İÇ MEKAN">İÇ MEKAN</option>
                  <option value="TAŞINAMZ KROKİ">TAŞINAMZ KROKİ</option>
                  <option value="TAŞINMAZ HARİTA">TAŞINMAZ HARİTA</option>
                  <option value="VAZİYET PLANI">VAZİYET PLANI</option>
                  <option value="KAT PLANI">KAT PLANI</option>
                  <option value="KAPAK FOTOĞRAFI">KAPAK FOTOĞRAFI</option>
                  <option value="ENERJİ KİMLİK BELGESİ">ENERJİ KİMLİK BELGESİ</option>
                </select>
                
              </div>
              <div class="d-flex flex-column w-100 gap-4">
                <button
                  variant="light"
                  class="w-100 btn btn-success"
                  @click="photoUpdate(photo)"
                >
                  Güncelle
                </button>
                <button
                  variant="light"
                  class="w-100 mt-2 btn btn-danger"
                  @click="photoDelete(photo._id.$oid)"
                >
                  <i class="flaticon2-trash text-white"></i>
                  Sil
                </button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </Card>

    <b-modal
      v-model="imageEditModal"
      size="2xl"
      id="note-modal"
      @shown="initCropper"
      @hidden="destroyCropper"
    >
      <template #modal-header>
        <h4>Resmi Düzenle</h4>
      </template>
      <div class="row">
        <div class="col-9" ref="cropperContainer">
          <img :src="imageSrc" class="img-fluid" alt="" />
        </div>
        <div class="col-3">
          <div class="row">
            <img
              :src="croppedDataUrl"
              class="img-fluid"
              ref="croppedImg"
              alt=""
            />
          </div>
          <div class="row" style="gap: 5px">
            <button class="btn btn-primary col mt-2" @click="zoomIn">
              Yakınlaştır
            </button>
            <button class="btn btn-primary col mt-2" @click="zoomOut">
              Uzaklaştır
            </button>
            <button class="btn btn-primary col mt-2" @click="rotateLeft">
              Sola Döndür
            </button>
            <button class="btn btn-primary col mt-2" @click="rotateRight">
              Sağa Döndür
            </button>
            <button class="btn btn-primary col mt-2" @click="flipHorizontal">
              Yatay Çevir
            </button>
            <button class="btn btn-primary col mt-2" @click="flipVertical">
              Dikey Çevir
            </button>
            <button class="btn btn-primary col mt-2" @click="croppedImg">
              Kırp
            </button>

            <button class="btn btn-primary col mt-2" @click="reset">
              Sıfırla
            </button>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="hideModal"> Vazgeç </b-button>
          <b-button variant="success" class="ml-4" @click="photoCropUpdate">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      imageEditModal: false,
      cropper: {},
      croppedDataUrl: "",
      imageSrc: "",
      albumname: "",
      albumdate: "",
      albumlocation: "",
      albumdescription: "",
      images: [],
      photos: [],
      cropBlob: null,
      photo: [],
      albumid : ""
    };
  },
  created() {
    this.getList();
  },

  watch: {
    imageEditModal(newValue, oldValue) {
      if (newValue) {
        this.initCropper();
      }
    },
  },
  methods: {
    initCropper() {
      const cropperContainer = this.$refs.cropperContainer;
      const imageElement = cropperContainer.querySelector("img"); // veya gerekli elementi bulmak için uygun seçiciyi kullanın
      const cropper = new Cropper(imageElement, {
        // Cropper.js yapılandırma seçeneklerini burada ayarlayabilirsiniz
      });
      this.imageEditModal = true;
      this.cropper = cropper;
    },
    destroyCropper() {
      const cropperContainer = this.$refs.cropperContainer;
      const imageElement = cropperContainer.querySelector("img");
      if (imageElement && imageElement.cropper) {
        imageElement.cropper.destroy();
      }
    },
    hideModal() {
      this.imageEditModal = false;
      $bvModal.hide("note-modal");
    },
    zoomIn() {
      this.cropper.zoom(0.1);
    },
    zoomOut() {
      this.cropper.zoom(-0.1);
    },
    rotateLeft() {
      this.cropper.rotate(-90);
    },

    rotateRight() {
      this.cropper.rotate(90);
    },
    flipHorizontal() {
      if (this.cropper) {
        this.cropper.scaleX(-this.cropper.imageData.scaleX || -1);
      }
    },
    flipVertical() {
      if (this.cropper) {
        this.cropper.scaleY(-this.cropper.imageData.scaleY || -1);
      }
    },
    croppedImg() {
      if (this.cropper) {
        const croppedCanvas = this.cropper.getCroppedCanvas();
        if (croppedCanvas) {
          croppedCanvas.toBlob((blob) => {
            this.blob = blob;
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.croppedDataUrl = reader.result;
            };
          });
        }
        this.cropper.destroy();
        this.cropper = null;
        this.initCropper();
      }
    },

    reset() {
      this.cropper.reset();
      this.croppedDataUrl = "";
      this.imageSrc = "/image/ilan1.png"; // Sıfırlama işleminden sonra ana resmi geri yükle
    },
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/album?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&album_id=${
        this.$route.params.albumid
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.albumname = res.response.data.albumname;
          this.albumdate = res.response.data.albumdate;
          this.albumdescription = res.response.data.albumdescription;
          this.albumlocation = res.response.data.albumlocation;
          this.albumid = res.response.data._id.$oid
           this.photos = res.response.data.photos;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    photoPost() {
      if (this.images.length > 0) {
        Swal.fire({
          title: "Yükleniyor...",
          html: "Lütfen Bekleyiniz...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          imageUrl: "https://www.boasnotas.com/img/loading2.gif",
          showCancelButton: false,
          showConfirmButton: false,

          didOpen: () => {
            Swal.showLoading();
          },
        });

        this.postLoader = true;
        const length = this.images.length;
        let updatedImage = 0;
        this.images.map((item) => {
          const formData = new FormData();
          formData.append("photo", item);
          formData.append("user_token", this.myUser);
          formData.append("album_id", this.$route.params.albumid);
          this.$ApiService
            .postFile("property/album/file", formData)
            .then((response) => {
              updatedImage = updatedImage + 1;
              this.getList();

              if (updatedImage === length) {
                this.postLoader = false;
                this.images = [];
                Swal.close();
                this.$generateNotification(
                  this,
                  "success",
                  "Fotoğraflar başarıyla eklendi."
                );
              }
            })
            .catch((err) => {
              this.$generateNotification(
                this,
                "error",
                "Fotoğraf Eklenirken Bir Hata ile Karşılaşıldı."
              );
              this.postLoader = false;
            });
        });
      } else {
        this.$generateNotification(this, "error", "Lütfen resim ekleyiniz.");
      }
    },
    photoUpdate(photo) {
      const formData = new FormData();
      formData.append("user_token", this.myUser);
      formData.append("photo_id", photo._id.$oid);
      formData.append("photo_description", photo.photo_description);
      formData.append("photo_type", photo.photo_type);
      this.$ApiService
        .postFile("property/album/filedesc", formData)
        .then((response) => {
          this.$generateNotification(
            this,
            "success",
            "Fotoğraf Güncellendi."
          );
          this.getList();
        })
        .catch((err) => {
          this.$generateNotification(
            this,
            "error",
            "Fotoğraf Eklenirken Bir Hata ile Karşılaşıldı."
          );
        });
    },
    photoDelete(id) {
      this.$dbFunctions
        .get(
          `property/album/filedelete?user_token=${this.myUser}&photo_id=${id}`
        )
        .then((res) => {
          this.getList();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editImg(photo) {
      this.imageSrc = "https://bankamdanapi.dvebdemo.com.tr/" + photo.photo;
      this.photo = photo;
      this.imageEditModal = true;
    },
    photoCropUpdate() {
      const file = new File([this.blob], "photo.jpeg");
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("user_token", this.myUser);
      formData.append("photo_id", this.photo._id.$oid);

      this.$ApiService
        .postFile("property/album/fileupdate", formData)
        .then((res) => {
          this.getList();
        });
    },
    updatePhotoDescription(key, newValue) {
      this.photos[key].photo_description = newValue;
      
    },
    updatePhotoType(key, newValue) {
      this.photos[key].photo_type = newValue;
      
    },

  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
<style lang="scss" scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0.3;
}
</style>
